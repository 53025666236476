<template>
  <div>
    <LandingPage />
    <SummarySection />
    <KeyThemes />
    <NumbersSection />
    <FeaturesSection />
    <DownloadSection />
  </div>
</template>

<script>
import LandingPage from "@/components/sections/LandingPage.vue";
import SummarySection from "@/components/sections/SummarySection.vue";
import KeyThemes from "@/components/sections/KeyThemes.vue";
import FeaturesSection from "@/components/sections/FeaturesSection.vue";
import NumbersSection from "@/components/sections/NumbersSection.vue";
import DownloadSection from "@/components/sections/DownloadSection.vue";

export default {
  name: "HomeView",
  components: {
    LandingPage,
    SummarySection,
    KeyThemes,
    FeaturesSection,
    NumbersSection,
    DownloadSection,
  },
};
</script>
<style scoped>
.landing-filler {
  min-height: 100vh;
}
</style>
