<template>
  <HomeView />
  <NavBar />
  <TheFooter />
  <CookieBanner />
  <div v-if="!goLive" class="overlay"></div>
</template>
<script setup>
import "lazysizes";
import HomeView from "@/views/HomeView.vue";
import NavBar from "@/components/ui/NavBar.vue";
import TheFooter from "@/components/ui/TheFooter.vue";
import CookieBanner from "@/components/ui/CookieBanner.vue"
import { reactive, computed, ref, onMounted } from "vue";
import { useHead } from "@vueuse/head";

const siteData = reactive({
  title: "CFP Sydney",
  // description: "Creative Footprint Montréal",
});

useHead({
  title: computed(() => siteData.title),

});

const goLive = ref(true)

function launchTime() {
  const targetDateTime = new Date('2023-11-26T18:59:00');
  const targetTimeZone = 'Europe/London';
  // Convert the target date and time to the desired timezone
  const targetDate = new Date(targetDateTime.toLocaleString('en-US', { timeZone: targetTimeZone }));

  // Get the current date in the desired timezone
  const currentDate = new Date();
  const currentTimeZoneOffset = currentDate.getTimezoneOffset();

  // Convert both dates to UTC to compare
  const targetDateUTC = Date.UTC(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate(),
    targetDate.getHours(),
    targetDate.getMinutes()
  );

  const currentDateUTC = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes()
  );

  goLive.value = currentDateUTC > targetDateUTC - (currentTimeZoneOffset * 60 * 1000);
}

launchTime()
onMounted(() => {
  if (!goLive.value) {
    document.body.style.overflow = 'hidden'

  }
})
</script>

<style lang="scss">
@font-face {
  font-family: "SkModernistRegular";
  src: url("~@/assets/fonts/sk-modernist-regular.woff2") format("woff2"),
    url("~@/assets/fonts/sk-modernist-regular.woff") format("woff");
}

@font-face {
  font-family: "SkModernistBold";
  src: url("~@/assets/fonts/sk-modernist-bold.woff2") format("woff2"),
    url("~@/assets/fonts/sk-modernist-bold.woff") format("woff");
}

@font-face {
  font-family: "SkModernistMono";
  src: url("~@/assets/fonts/sk-modernist-mono.woff2") format("woff2"),
    url("~@/assets/fonts/sk-modernist-mono.woff") format("woff");
}

#app {
  font-family: SkModernistRegular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background: #201f1d;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html,
body {
  background: #201f1d;
  font-size: 0.9rem;
  // overflow: hidden;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.overlay {
  position: fixed;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("@/assets/img/placeholder.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

html {
  height: -webkit-fill-available;
}

.section {
  position: relative;
  z-index: 2;
}

.title-underline {
  text-decoration: underline;
  text-decoration-thickness: 4px;
}

.swiper-wrapper {
  transition-timing-function: linear;
}

h3 {
  text-align: center;
  font-family: SkModernistBold;
  font-size: 2rem;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.3s ease-in;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.btn-outline-light {
  border-color: #e8fcd4 !important;
  color: #e8fcd4 !important;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.open>.dropdown-toggle.btn-outline-light {
  background-color: #e8fcd4 !important;
  color: #201f1d !important;
}

.share-button {
  background-color: #e8fcd4 !important;
}

.share-button {
  background-color: #e8fcd4 !important;
  color: #201f1d !important;
}

.share-button__icon path {
  fill: #201f1d !important;
}
</style>
