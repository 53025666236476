<template>
  <nav :class="[menuOpen ? 'nav-mobile-open' : '']">
    <div class="d-block d-md-none mobile-container container-fluid">
      <div class="row h-100">
        <div class="col-4 h-100 d-flex align-items-center align-items-center">
          <div
            @click="menuOpen = !menuOpen"
            class="nav-btn d-flex justify-content-center align-items-center flex-column"
          >
            <span :class="[menuOpen ? 'open-first' : '']"></span>
            <span :class="[menuOpen ? 'open-second' : '']"></span>
          </div>
        </div>
        <div class="col-4">
          <div class="d-flex justify-content-center align-items-center h-100">
            <div class="mobile-logo">
              <img
                class="img-fluid"
                src="@/assets/img/vibelab-logo-black.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="col-4"></div>
      </div>
      <div :class="[menuOpen ? 'menu-open' : '']" class="mobile-fullpage">
        <div class="container-fluid mt-5 row">
          <div
            @click="changeChapter(link, index)"
            v-for="(link, index) in links"
            :key="index"
            class="col-12 p-0 text-start my-3"
            @mouseover="slideBg(link)"
            @mouseleave="defaultBg"
            :class="[index === 0 ? 'landing-hidden' : '']"
          >
            <div class="mob-link" @click="menuOpen = !menuOpen">
              {{ link.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-grid container-fluid h-100 link-container p-0 m-0">
      <div
        @click="changeChapter(link, index)"
        v-for="(link, index) in links"
        :key="index"
        class="link d-flex align-items-center justify-content-center"
        @mouseover="slideBg(link)"
        @mouseleave="defaultBg"
        :class="[index === 0 ? 'landing-hidden' : '']"
      >
        {{ link.name }}
      </div>
      <div
        :style="[`transform: translateX(${bgPos}%)`]"
        class="active-link"
      ></div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      menuOpen: false,
      links: [
        { name: "Landing", url: "", pos: -100 },
        { name: "Summary", url: "summary", pos: 0 },
        { name: "Recommendations", url: "themes", pos: 100 },
        { name: "CFP Score", url: "numbers", pos: 200 },
        { name: "Preview", url: "features", pos: 300 },
        { name: "Download", url: "download", pos: 400 },
      ],
      currentIndex: 0,
      bgPos: -100,
      faders: null,
      slideViaHover: false,
    };
  },
  methods: {
    changeChapter(link, index) {
      const y =
        document.getElementById(`${link.url}`).getBoundingClientRect().top +
        window.scrollY;
      window.scroll({
        top: y,
        behavior: "smooth",
      });
      this.currentIndex = index;
      // location.hash = `#${link.url}`;
    },
    slideBg(link) {
      this.slideViaHover = true;
      this.bgPos = link.pos;
    },
    defaultBg() {
      this.slideViaHover = false;
      this.bgPos = this.links[this.currentIndex].pos;
    },
    observe() {
      this.faders = document.querySelectorAll(".section");

      const appearOnScroll = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              history.pushState({}, "", `#${entry.target.id}`);
              let currentlyObserving = this.links.find(
                (link) => link.url === entry.target.id
              );

              if (!this.slideViaHover) {
                this.bgPos = currentlyObserving.pos;
                this.currentIndex = this.links.findIndex(
                  (link) => link === currentlyObserving
                );
              }
            }
          });
        },
        {
          rootMargin: `-100px`,
        }
      );
      this.faders.forEach((fader) => {
        appearOnScroll.observe(fader);
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.observe();
    });
  },
};
</script>
<style scoped lang="scss">
.link-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.mob-link {
  font-size: 2rem !important;
  color: #201f1d;
}
.mob-link:hover {
  cursor: pointer;
}
.mobile-fullpage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: #e8fcd4;
  z-index: -1;
  transform: translateY(100%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.menu-open {
  transform: translateX(0);
}
.nav-btn {
  width: 2.6rem;
  height: 3rem;
}
.nav-btn:hover {
  cursor: pointer;
}
span {
  width: 100%;
  height: 2px;
  background: #201f1d;
  border-radius: 2px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
span:nth-child(odd) {
  margin-top: 1.5;
  transform-origin: center left;
}
span:nth-child(even) {
  margin-top: 0.6rem;
  transform-origin: center left;
}

.open-first {
  transform: translate(0.3rem, -0.5rem) rotate(42deg);
}
.open-second {
  transform: translate(0.3rem, 0.5rem) rotate(-42deg);
}
.mobile-logo {
  width: 7rem;
}
.mobile-container {
  height: 100%;
}
.landing-hidden {
  display: none !important;
}
.link {
  border-radius: 50px;
  height: 100%;
  color: #000;
}
.link:hover {
  cursor: pointer;
}
.active-link {
  z-index: -2;
  width: 20%;
  border-radius: 50px;
  left: 0;
  transition: all ease 300ms;
  will-change: transform;
  position: absolute;
  height: 100%;
  background: #a0a592;
}

.nav-mobile-open {
  border-radius: 0;
}

nav {
  transition: border-radius 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  overflow: hidden;
  border-radius: 50px;
  text-align: center;
  position: fixed;
  width: 100%;
  background: #e8fcd4;
  height: 4rem;
  left: 0;
  right: 0;
  margin: auto;
  top: 0rem;
  z-index: 2;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media (min-width: 768px) {
  nav {
    overflow: hidden;
    border-radius: 50px;
    text-align: center;
    position: fixed;
    width: 47rem;
    background: #e8fcd4;
    height: 4rem;
    left: 0;
    right: 0;
    margin: auto;
    top: 2rem;
    z-index: 2;
  }
}
* {
  font-size: 1rem;
}
</style>