import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/bootstrap.min.css'
import { createPrismic } from '@prismicio/vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
import MoreBtn from '@/components/ui/MoreBtn.vue'
import DlBtn from '@/components/ui/DlBtn.vue'
import { createHead } from '@vueuse/head'
import VueGtag from 'vue-gtag-next'

const head = createHead()

const prismic = createPrismic({
  endpoint: 'vibelab'
})

AOS.init({
  offset: 100, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 800, // values from 0 to 3000, with step 50ms
  easing: 'ease' // default easing for AOS animations
  // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
})

const app = createApp(App)

const getGDPR = localStorage.getItem('GDPR:accepted')

if (typeof getGDPR !== 'undefined' && getGDPR === 'true') {
  app.use(VueGtag, {
    enabled: true,
    property: {
      id: "G-T91M9YXZKZ"
  },
    config: {
      id: 'G-T91M9YXZKZ'
    },
 
  })
} else if (typeof getGDPR !== 'undefined' && getGDPR === 'false') {
  app.use(VueGtag, {
    enabled: false,
  property: {
    id: "G-T91M9YXZKZ"
  },
    config: {
      id: 'G-T91M9YXZKZ'
    },
 
  })
}

app.use(prismic)
app.use(head)
app.component('DlBtn', DlBtn)
app.component('MoreBtn', MoreBtn)
app.use(router)

app.mount('#app')
