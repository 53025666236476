<template>
  <div id="themes" class="section themes">
    <div class="container-fluid row d-flex justify-content-center m-0">
      <div class="col-12 title text-center mb-5 pb-5 pt-5 mt-5">
        Key Recommendations:
      </div>

      <div
        class="col-md-8 col-12 mb-3"
        v-for="(theme, index) in themes"
        :key="index"
        @click="toggleAnswer(index)"
        data-aos="fade-in"
       
      >
        <div
          class="py-3 theme-wrapper px-3"
          :style="[
            clickedAnswer === index || index === themes.length - 1
              ? ' background-color: #e8fcd4; color: #201f1d;'
              : '',
          ]"
        >
          <div class="col-12 position-relative">
            <h4 class="my-2 py-1 mx-1 me-4">{{ theme.title }}</h4>
            <span
              class="expand-cross d-flex justify-content-center align-items-center"
              ><svg
                :style="[
                  clickedAnswer === index
                    ? 'transform:rotate(45deg)!important'
                    : '',
                ]"
                width="15"
                height="15"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 5.04H5.04V9H3.96V5.04H0V3.96H3.96V0H5.04V3.96H9V5.04Z"
                  fill="currentColor"
                ></path></svg></span>
          </div>

          <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
          >
            <div v-if="clickedAnswer === index" class="col-12 answer mt-3" v-html="theme.body">
            
            </div>
          </transition>
        </div>
      </div>
   
     
    
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clickedAnswer: null,
      themes: [
        {
          title: "1. Strengthen nightlife",
          body: "Strengthen nightlife precincts throughout Sydney to promote equitable, transit-linked nightlife access. ",
        },
        {
          title: "2. Simplify ownership",
          body: "Simplify pathways to new venue ownership and continued operation.",
        },
        {
          title: "3. Increase access",
          body: "Restructure funding processes and improve grant literacy, particularly for emerging, small, and grassroots music actors.",
        },
         {
          title: "4. Enable safety",
          body: "Support an equitable approach to policing and safety of nightlife venues through alignment with existing commitments to equity and inclusivity.",
        },
         {
          title: "5. Enhance export",
          body: "Enable local artists to develop international audiences while residing in NSW: increase international discoverability of Sydney music artists and scenes on the global stage.",
        },
        {
          title: "These recommendation include, among others:",
          body: "<ul><li>Proactively plan the expansion of night-time infrastructure in areas where they can flourish, such as Pyrmont-Ultimo, North Alexandria, Dulwich Hill-Lewisham;</li><li>Consider new models of community-controlled nightlife spaces, through a potential cohort structure to pilot community-owned nightlife spaces;</li><li>Align professional development quick response grants with increases in CPI (Consumer Price Index), business expenses, and wages;</li><li>Establish regular stand-ups (informal meetings) between police and venue owners/operators in venue-dense districts;</li><li>Create pathways for diverse people with lived experience in nightlife to train and fill security roles;</li><li>Enable local artists to develop international audiences while residing in NSW: increase international discoverability of Sydney music artists and scenes on the global stage.</li>",
        },
      ],
    };
  },
  methods: {
    toggleAnswer(e) {
      if (this.clickedAnswer === e) {
        this.clickedAnswer = null;
      } else {
        this.clickedAnswer = e;
      }
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>
<style scoped>
.read-btn {
  padding: 0.8rem 2rem 0.8rem 2rem;
  /* border: 1px solid white; */
  border-radius: 30px;
  z-index: 1;
}
.read-btn:hover {
  cursor: pointer;
}
.expand-cross {
  position: absolute;
  right: 0.2rem;
  top: 0;
  bottom: 0;
  margin: auto;
  font-family: SkModernistBold;
  height: 1.8rem;
}
svg {
  transition: transform 650ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.theme-wrapper:hover svg {
  transform: rotate(180deg);
}
.theme-wrapper:hover {
  background-color: #e8fcd4;
  color: #201f1d;
}
.theme-wrapper {
  color: #e8fcd4;
  border: 1px solid #e8fcd4;
  border-radius: 40px;
  background-color: transparent;
  transition: all 0.15s ease-in-out;
}
.theme-wrapper:hover {
  cursor: pointer;
}
.expand-enter-active,
.expand-leave-active {
  transition: height 200ms ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

.title {
  line-height: 2rem;
  font-size: 1.8rem;
  z-index: 1;
  color: #e8fcd4;
}
.themes {
  min-height: auto;
  /* background: #201f1d; */
  position: relative;
}
@media (min-width: 768px) {
  .themes {
    min-height: 100vh;
  }
  .title {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}

h3 {
  text-align: center;
  font-family: SkModernistBold;
  font-size: 2rem;
}
.answer {
  font-size:1.2rem;
}
</style>