<template>
  <footer>
    <div
      class="
        container-fluid
        d-flex
        justify-content-center
        align-items-center
        h-100
      "
    >
      <div
        class="
          footer-fill
          d-flex
          justify-content-center
          align-items-center
          h-100
        "
      >
      <div class="col-12 row">
        <div class="col-md-4 col-12 my-3">
          VibeLab B.V. <br>
NL.862.328.974.B01<br>
KVK: 82080534
        </div>
         <div class="col-md-4 col-12 my-3">
        Rapenburg 97-2<br>
1011 TW Amsterdam<br>
The Netherlands
        </div>
         <div class="col-md-4 col-12 my-3 d-flex align-items-center justify-content-center">
        <a href="https://vibe-lab.org/imprint">All rights reserved {{ new Date().getFullYear() }}</a>
        </div>

      </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
.footer-fill {
  z-index: 3;
  height: 100%;
  width: 100%;
}

footer {
  z-index: 1;
  margin-top: auto;
  height: auto;
  background: #201f1d;
  position: relative;
  border-top: 1px solid #e8fcd4;
  color:#a0a592;
  text-align: center;
}
a {
    color:#a0a592;
}
a:hover {
    color:#a0a592;
}
</style>